<template>
  <div class="back">
    <span @click="handleBack" class="back_content">{{$t('home.back')}}</span>
  </div>
</template>

<script>
export default {
  name: "Back",
  data() {
    return {};
  },
  methods: {
    handleBack() {
      this.$emit("handleBack");
    },
  },
};
</script>

<style scoped lang="scss">
.back {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  padding-left: 210px;

  .back_content {
    width: 46px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      color: #1ab370;
    }
  }
}
</style>
